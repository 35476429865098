import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'
import { spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Tabs } from '@thg-commerce/gravity-patterns'
import { TabsProps } from '@thg-commerce/gravity-patterns/Tabs'

export enum TextColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface TabbedSetProps {
  title?: {
    text?: string
    color?: TextColor
  }
  subtitle?: {
    text?: string
    color?: TextColor
  }
  tabProps: Omit<TabsProps, 'i18nText'>
  alignment?: HorizontalAlignment
  className?: string
}

const Title = styled.h2<{ color?: TextColor }>`
  ${(props) =>
    Text(
      props.theme.widget.tabbedSet.title.font.entry,
      props.theme.widget.tabbedSet.title.font.style,
      props.theme.widget.tabbedSet.title.font.hasScaledText,
    )};
  margin-bottom: ${spacing(1)};
  color: ${(props) =>
    props.color && props.color === TextColor.LIGHT
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.greys.darker};
`

export const Subtitle = styled.h2<{ color?: TextColor }>`
  ${(props) =>
    Text(
      props.theme.widget.tabbedSet.subtitle.font.entry,
      props.theme.widget.tabbedSet.subtitle.font.style,
    )};
  margin-bottom: ${spacing(3)};
  color: ${(props) =>
    props.color && props.color === TextColor.LIGHT
      ? props.theme.colors.palette.greys.light
      : props.theme.colors.palette.greys.darker};
`

export const Container = styled.div<{ alignment?: HorizontalAlignment }>`
  text-align: ${(props) => props.alignment || 'center'};
`

export const TabbedSet = (props: TabbedSetProps) => {
  return (
    <Container alignment={props.alignment} className={props.className}>
      {props.title?.text && (
        <Title color={props.title.color} data-testid="tabbed-set-title">
          {props.title.text}
        </Title>
      )}
      {props.subtitle?.text && (
        <Subtitle
          color={props.subtitle.color}
          data-testid="tabbed-set-subtitle"
        >
          {props.subtitle.text}
        </Subtitle>
      )}
      <Tabs {...props.tabProps} />
    </Container>
  )
}
