import * as React from 'react'

import {
  GlobalTabbedWidgetSet,
  ResponsiveProductTabs,
  Widget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { mq, spacing, styled } from '@thg-commerce/gravity-theme'
import {
  TabbedSet,
  TabbedSetProps,
} from '@thg-commerce/enterprise-widget-tabbed-set'

import { loadWidget } from '../WidgetRenderer/widgetMap'
import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'
import { TabProps } from '@thg-commerce/gravity-patterns/Tabs/Tabs'

export type TabbedSetWidgetRendererProps = BaseWidgetProps &
  (
    | Omit<GlobalTabbedWidgetSet, 'query'>
    | Omit<ResponsiveProductTabs, 'query'>
  ) & { widgetIndex: number }

const StyledTabbedSet = styled(TabbedSet)`
  margin: 0 auto ${spacing(5)};
  max-width: ${(props) => props.theme.site.siteWidth};
  padding: 0 ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: 0 ${spacing(4)};
  }
`

export const TabbedSetWidgetRenderer = (
  props: TabbedSetWidgetRendererProps,
) => {
  const getTabbedSetComponent = (
    banners: (Widget | null)[],
    titleKeyPrefix: string,
  ) => {
    return banners.reduce<TabProps[]>((accumulator, widget, index) => {
      const widgetComponent = widget ? loadWidget(widget['__typename']) : null
      if (widget && widgetComponent) {
        if (
          widget['__typename'] !== 'ResponsiveProductBlockCarousel' ||
          typeof widget['productBlockList']?.['productBlocks'] !==
            'undefined' ||
          typeof widget['productList']?.['products'] !== 'undefined' ||
          typeof widget['responsiveSkuList'] !== 'undefined'
        ) {
          accumulator.push({
            title: props[`${titleKeyPrefix}${index + 1}`],
            content: React.createElement(widgetComponent, {
              ...widget,
              widgetType: widget['__typename'],
              key: widget.id,
              pagePath: props.pagePath,
              widgetIndex: props.widgetIndex,
            }),
          })
        }
      }
      return accumulator
    }, [])
  }

  if (!props.banners) {
    return null
  }

  if (props.__typename === WidgetTypes.GlobalTabbedWidgetSet) {
    const tabs = getTabbedSetComponent(props.banners, 'tabbedWidgetTabTitle_')
    if ((tabs || []).length === 0) {
      return null
    }
    const tabbedSetProps: TabbedSetProps = {
      title: {
        text: props.tabbedWidgetSetTitle || '',
      },
      subtitle: { text: props.tabbedWidgetSetSubTitle || '' },
      tabProps: {
        tabs,
      },
    }

    if (tabbedSetProps.tabProps.tabs?.length > 1) {
      return <StyledTabbedSet {...tabbedSetProps} />
    }
  }

  if (props.__typename === WidgetTypes.ResponsiveProductTabs) {
    const tabs = getTabbedSetComponent(props.banners, 'tabTitle_')
    if ((tabs || []).length === 0) {
      return null
    }
    const tabbedSetProps: TabbedSetProps = {
      title: {
        text: props.responsiveProductTabsTitle || '',
      },
      tabProps: {
        tabs,
      },
    }

    if (tabbedSetProps.tabProps.tabs?.length > 0) {
      return <StyledTabbedSet {...tabbedSetProps} />
    }
  }

  return null
}
